import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Space, Size } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { P, Link, ComponentLinks } from '../common';
import { colorBrand3 } from '@hse-design/tokens';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "space"
    }}>{`Space`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Space } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=2878%3A36'} storybook={'/?path=/story/layout-space--playground'} vueStorybook={'/?path=/story/layout-space--playground'} name={'Space'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Space – это компонент для добавления отступа между блоками.
Используйте компонент Space там, где вы по каким-то причинам не можете создать отступы через css-свойство margin.`}</p>
    <p>{`Отступ имеет два положения: вертикальное и горизонтальное. Для задания нужного передайте одноименный проп: `}<inlineCode parentName="p">{`vertical`}</inlineCode>{` или `}<inlineCode parentName="p">{`horizontal`}</inlineCode>{`.`}</p>
    <p>{`С помощью пропа `}<inlineCode parentName="p">{`size`}</inlineCode>{` можно задать размер отступа. Размеры отступов соответствуют `}<a parentName="p" {...{
        "href": "/src-tokens-other/#%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D0%BD%D1%8B%D0%B5-%D0%BE%D1%82%D1%81%D1%82%D1%83%D0%BF%D1%8B-spacing"
      }}>{`токенам отступов`}</a>{`.
В качестве значения пропа используйте свойство `}<inlineCode parentName="p">{`Size`}</inlineCode>{` у компонента `}<inlineCode parentName="p">{`Space`}</inlineCode>{`. Например, `}<inlineCode parentName="p">{`Space.Size.small`}</inlineCode>{`, `}<inlineCode parentName="p">{`Space.Size.small_3x`}</inlineCode>{`.
По умолчанию размер отступа равен `}<inlineCode parentName="p">{`medium`}</inlineCode>{`.`}</p>
    <Playground __position={1} __code={'<Space\n  vertical\n  horizontal\n  size={Space.Size.large}\n  style={{ backgroundColor: colorBrand3 }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Space,
      Size,
      Playground,
      Props,
      P,
      Link,
      ComponentLinks,
      colorBrand3,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Space vertical horizontal size={Space.Size.large} style={{
        backgroundColor: colorBrand3
      }} mdxType="Space" />
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "положение"
    }}>{`Положение`}</h3>
    <p>{`Отступ можно задать как по вертикали, так и по горизонтали. Используйте одноименные пропы `}<inlineCode parentName="p">{`vertical`}</inlineCode>{`, `}<inlineCode parentName="p">{`horizontal`}</inlineCode>{` для задания нужного положения`}</p>
    <Playground __position={2} __code={'<>\n  <div style={{ display: \'flex\' }}>\n    <div>Element</div>\n    <Space\n      style={{ backgroundColor: colorBrand3 }}\n      size={Space.Size.large}\n      horizontal\n    />\n    <div>Element</div>\n  </div>\n  <Space size={Space.Size.small} vertical />\n  <div>\n    <div>Element</div>\n    <Space\n      style={{ backgroundColor: colorBrand3 }}\n      size={Space.Size.large}\n      vertical\n    />\n    <div>Element</div>\n  </div>\n</>'} __scope={{
      props,
      DefaultLayout,
      Space,
      Size,
      Playground,
      Props,
      P,
      Link,
      ComponentLinks,
      colorBrand3,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <div style={{
          display: 'flex'
        }}>
      <div>Element</div>
      <Space style={{
            backgroundColor: colorBrand3
          }} size={Space.Size.large} horizontal mdxType="Space" />
      <div>Element</div>
    </div>
    <Space size={Space.Size.small} vertical mdxType="Space" />
    <div>
      <div>Element</div>
      <Space style={{
            backgroundColor: colorBrand3
          }} size={Space.Size.large} vertical mdxType="Space" />
      <div>Element</div>
    </div>
  </>
    </Playground>
    <h3 {...{
      "id": "размер"
    }}>{`Размер`}</h3>
    <p>{`С помощью пропа `}<inlineCode parentName="p">{`size`}</inlineCode>{` можно задать размер отступа. Размеры отступов соответствуют `}<a parentName="p" {...{
        "href": "/src-tokens-other/#%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D0%BD%D1%8B%D0%B5-%D0%BE%D1%82%D1%81%D1%82%D1%83%D0%BF%D1%8B-spacing"
      }}>{`токенам отступов`}</a>{`.
В качестве значения пропа используйте свойство `}<inlineCode parentName="p">{`Size`}</inlineCode>{` у компонента `}<inlineCode parentName="p">{`Space`}</inlineCode>{`. Например, `}<inlineCode parentName="p">{`Space.Size.small`}</inlineCode>{`, `}<inlineCode parentName="p">{`Space.Size.small_3x`}</inlineCode>{`. По умолчанию размер отступа равен `}<inlineCode parentName="p">{`medium`}</inlineCode></p>
    <Playground __position={3} __code={'() => {\n  return (\n    <div style={{ display: \'flex\', flexFlow: \'row wrap\' }}>\n      {Object.keys(Space.Size)\n        .reverse()\n        .map(s => (\n          <>\n            <Space\n              style={{ backgroundColor: colorBrand3 }}\n              size={Space.Size[s]}\n              vertical\n              horizontal\n            />\n            <Space size={Space.Size.small} vertical horizontal />\n          </>\n        ))}\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Space,
      Size,
      Playground,
      Props,
      P,
      Link,
      ComponentLinks,
      colorBrand3,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        return <div style={{
          display: 'flex',
          flexFlow: 'row wrap'
        }}>
        {Object.keys(Space.Size).reverse().map(s => <><Space style={{
              backgroundColor: colorBrand3
            }} size={Space.Size[s]} vertical horizontal mdxType="Space" /><Space size={Space.Size.small} vertical horizontal mdxType="Space" /></>)}
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Space} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются корневому элементу `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      